import QR from '../../assets/svgs/QR.png';

export const MoreAboutUs = () => {
	return (
		<div className='flex w-full flex-col items-center justify-center gap-5 bg-white p-10 pt-20 md:flex-row lg:pt-10'>
			<img
				src={QR}
				alt='QR'
				className='h-auto w-96'
			/>

			<div className='w-[100%] text-center md:w-[50%] md:text-left'>
				<h3 className='border-l-4 border-r-4 border-primary-700 py-2 pl-4 text-3xl font-semibold text-primary-800 md:border-r-0'>
					That is a brief about us
				</h3>
				<p className='mb-2 mt-2 text-xl font-semibold text-gray-700'>
					Explore our portfolio by scanning our QR code OR by{' '}
					<a
						rel='noreferrer'
						href='https://drive.google.com/drive/folders/1Oe5yK7qx_YFE96cCdwgkwxjYCHzEBVQ3?usp=sharing'
						target='_blank'
						className='italic text-primary-700'>
						clicking HERE{' '}
						<svg
							className='inline -translate-y-[2px] text-primary-700'
							xmlns='http://www.w3.org/2000/svg'
							x='0px'
							y='0px'
							width='20'
							height='20'
							viewBox='0 0 48 48'
							stroke='#FD8100'
							fill='#FD8100'>
							<path d='M 40.960938 4.9804688 A 2.0002 2.0002 0 0 0 40.740234 5 L 28 5 A 2.0002 2.0002 0 1 0 28 9 L 36.171875 9 L 22.585938 22.585938 A 2.0002 2.0002 0 1 0 25.414062 25.414062 L 39 11.828125 L 39 20 A 2.0002 2.0002 0 1 0 43 20 L 43 7.2460938 A 2.0002 2.0002 0 0 0 40.960938 4.9804688 z M 12.5 8 C 8.3826878 8 5 11.382688 5 15.5 L 5 35.5 C 5 39.617312 8.3826878 43 12.5 43 L 32.5 43 C 36.617312 43 40 39.617312 40 35.5 L 40 26 A 2.0002 2.0002 0 1 0 36 26 L 36 35.5 C 36 37.446688 34.446688 39 32.5 39 L 12.5 39 C 10.553312 39 9 37.446688 9 35.5 L 9 15.5 C 9 13.553312 10.553312 12 12.5 12 L 22 12 A 2.0002 2.0002 0 1 0 22 8 L 12.5 8 z'></path>
						</svg>
					</a>
					.
				</p>
				<p className='mb-20 mt-2 text-xl font-semibold text-gray-700'>
					Learn more about our contracting and consultancy services, discover
					our projects, and see how we bring expertise and innovation to every
					project we undertake. From start to finish, we are committed to
					delivering excellence and ensuring our clients' success.
				</p>
			</div>
		</div>
	);
};
