import React, { useState } from 'react';
import Compass from '../../assets/imgs/compass.png';
import Crane from '../../assets/imgs/crane-1.png';
import Renovation from '../../assets/imgs/renovation.png';
import Toolbox from '../../assets/imgs/toolbox.png';
import { motion } from 'framer-motion';

const services = [
	{
		title: 'Construction',
		icon: Crane,
	},
	{
		title: 'Architectural Design',
		icon: Compass,
	},
	{
		title: 'Consultancy',
		icon: Renovation,
	},
	{
		title: 'Project Management',
		icon: Toolbox,
	},
];

const ServiceCard = (props) => {
	const { index, title, icon } = props;
	const [isHovered, setIsHovered] = useState(false);

	return (
		<motion.li
			onHoverStart={() => setIsHovered(true)}
			onHoverEnd={() => setIsHovered(false)}
			whileHover={{ scale: 1.05 }}
			transition={{ duration: 0.5 }}
			className='wrapper flex h-40 items-center gap-1 overflow-hidden rounded-3xl border-2 border-solid border-primary-800'>
			<motion.div
				className='number-wrapper flex h-full w-[50%] items-center justify-center bg-primary-800 p-2 font-semibold'
				style={{
					fontSize: isHovered ? '8rem' : '6rem',
					color: isHovered ? '#FD8100' : '#fff',
				}}
				transition={{ duration: 0.5 }}>
				{index}
			</motion.div>

			<div className='flex w-full flex-col items-center justify-center gap-3'>
				<img
					src={icon}
					alt='service-icon'
					className='h-16 w-16'
				/>

				<h4 className='text-center text-xl font-semibold text-primary-800'>
					{title}
				</h4>
			</div>
		</motion.li>
	);
};

export const Services = () => {
	return (
		<div className='flex h-fit w-full flex-col justify-center gap-10 bg-white p-8 md:h-[50vh]'>
			<h3 className='section-title'>Our Services</h3>
			<ul className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4'>
				{services.map((service, index) => (
					<ServiceCard
						key={index}
						index={index + 1}
						icon={service.icon}
						title={service.title}
					/>
				))}
			</ul>
		</div>
	);
};
