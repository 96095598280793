import { otherProjects } from '../../data/projects';

const TableHeadItem = (props) => {
	const { title } = props;

	return (
		<th className='whitespace-nowrap border border-l-0 border-r-0 border-solid bg-[#f6f6f6] px-3 py-3 text-center align-middle text-sm font-semibold uppercase'>
			{title}
		</th>
	);
};

const TableCell = (props) => {
	const { children } = props;

	return (
		<td className='border border-l-0 border-r-0 border-solid px-3 py-3 text-center align-middle text-sm'>
			{children}
		</td>
	);
};

export const ProjectsTable = () => {
	return (
		<table className='w-full table-auto'>
			<thead>
				<tr>
					<TableHeadItem title='' />
					<TableHeadItem title='Project Name' />
					<TableHeadItem title='Owner' />
					<TableHeadItem title='Contractor' />
					<TableHeadItem title='Description' />
					<TableHeadItem title='Location' />
				</tr>
			</thead>
			<tbody>
				{otherProjects.map((project, index) => {
					const { id, title, client, location, contractor, description } =
						project;

					return (
						<tr key={index + '-row-other-projects'}>
							<TableCell>{index + 1}</TableCell>
							<TableCell>{title}</TableCell>
							<TableCell>{client}</TableCell>
							<TableCell>{contractor || '-'}</TableCell>
							<TableCell>{description}</TableCell>
							<TableCell>{location || '-'}</TableCell>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};
