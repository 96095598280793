import { useSearchParams } from 'react-router-dom';
import AllProjects from '../project/AllProjects';
import SingleProject from '../project/SingleProject';

export default function OurProjects() {
	let [searchParams] = useSearchParams();
	const projectId = searchParams.get('id');

	if (projectId) return <SingleProject />;
	else return <AllProjects />;
}
