import clsx from 'clsx';
import { useRef } from 'react';
import Slider from 'react-slick';
import ShortArrowDown from '../../assets/svgs/short-arrow-down.svg';

function Arrow(props) {
	const { className, isRight, style, onClick } = props;

	return (
		<div
			className={clsx(
				className,
				'!inline-flex items-center justify-center rounded-full bg-[#1c295a] before:hidden'
			)}
			style={{ ...style, backgroundColor: '#1c295a', borderRadius: '50%' }}
			onClick={onClick}>
			<img
				alt='short-arrow-down'
				className={isRight ? '-rotate-90' : '-ml-[2px] rotate-90'}
				src={ShortArrowDown}
			/>
		</div>
	);
}

export const OurCertificates = () => {
	const sliderRef = useRef(null);

	const settings = {
		dots: true,
		infinite: true,
		speed: 1000,
		autoplay: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: <Arrow />,
		nextArrow: <Arrow isRight />,
	};

	const gallery = [
		'/certificates/1.png',
		'/certificates/2.png',
		'/certificates/3.png',
		'/certificates/4.png',
		'/certificates/5.png',
		'/certificates/6.png',
		'/certificates/7.png',
	];

	return (
		<>
			<h3 className='section-title'>Our Certificates</h3>
			<div className='my-8 mb-20'>
				<div className='slider-container mx-auto mt-12 h-[60vh] w-[70%] max-w-[1000px]'>
					<Slider
						ref={sliderRef}
						{...settings}>
						{gallery.map((client, index) => (
							<div
								key={index}
								className='!flex w-full items-center justify-center'>
								<img
									src={client}
									key={index + 'gallery-item'}
									alt={index + 'gallery-item'}
									className='h-[60vh] max-h-full w-auto'
								/>
							</div>
						))}
					</Slider>
				</div>
			</div>
		</>
	);
};
