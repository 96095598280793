import ConstructionImg from '../../assets/imgs/construction.avif';
import ArchitecturalDesignImg from '../../assets/imgs/architectural-design.webp';
import ConsultancyImg from '../../assets/imgs/consultancy.avif';
import ProjectManagementImg from '../../assets/imgs/project-management.jpg';
import clsx from 'clsx';

const ImageWrapper = ({ src, className }) => {
	return (
		<img
			src={src}
			alt='ConstructionImg'
			className={clsx(
				className,
				'h-96 w-[30rem] rounded-lg shadow-lg shadow-black/30'
			)}
		/>
	);
};

export default function OurServices() {
	return (
		<div className='mx-auto flex w-full max-w-[1500px] items-center justify-between'>
			<div className='mx-auto min-h-[calc(100vh_-_64px)] w-full p-8 lg:w-[85%] lg:p-10'>
				<h3 className='section-title'>Our Services</h3>
				<p className='mx-auto mt-5 w-[80%] text-center text-lg'>
					<b>SAWAED</b> for Contracting is an integrated construction company
					offering a comprehensive range of services tailored to meet our
					clients' diverse needs and requirements. Our capabilities span various
					sectors, each meticulously managed and executed with expertise.
				</p>

				<div className='mx-auto mt-0 flex flex-col gap-20 pb-10 lg:mt-20'>
					<div className='flex flex-col items-center gap-10 lg:flex-row'>
						<ImageWrapper
							className='mt-[42px]'
							src={ConstructionImg}
						/>

						<div className='flex flex-col gap-5 text-justify'>
							<h3 className='section-title mx-auto text-center !text-2xl font-semibold !text-black'>
								Construction
							</h3>

							<p>
								At<b> Sawaed</b>, we are specialized in delivering high-quality
								construction solutions across various sectors. From industrial
								projects to commercial and residential buildings, our
								experienced team ensures that each project meets the highest
								standards of quality and efficiency. It includes:
							</p>
							<ul className='list-disc pl-14'>
								<li>
									<b>HVAC Systems Installation</b>: Comprehensive installation
									of central air conditioning systems for large-scale projects
									such as hospitals, stadiums, and factories. We ensure
									optimized climate control tailored to each facility.
								</li>
								<li>
									<b>Electrical Systems</b>: Full-service electrical works
									including installation of electrical panels, wiring, and
									switchgear to ensure the safe and efficient operation of power
									systems.
								</li>
								<li>
									<b>Fire Safety Systems</b>: We design, supply, and install
									fire safety networks including fire alarms, extinguishing
									systems (water and gas), and fire pumps to safeguard
									properties.
								</li>
								<li>
									<b>Civil & Architectural Works</b>: From site preparation to
									architectural finishing, we manage the entire civil
									construction process, ensuring each project is delivered on
									time and within budget.
								</li>
							</ul>
						</div>
					</div>

					<div className='flex flex-col items-center gap-10 lg:flex-row-reverse'>
						<ImageWrapper src={ArchitecturalDesignImg} />

						<div className='flex flex-col gap-5 text-justify'>
							<h3 className='section-title mx-auto text-center !text-2xl font-semibold !text-black'>
								Architectural Design
							</h3>

							<p>
								Our architectural design services bring creativity and technical
								expertise together to deliver innovative, functional, and
								sustainable designs. We work closely with clients to ensure that
								each project aligns with their vision and the functional
								requirements of the space.
							</p>
							<ul className='list-disc pl-14'>
								<li>
									<b>Mechanical & Electrical Design</b>: Expert design of
									mechanical, electrical, and HVAC systems, ensuring optimal
									functionality, energy efficiency, and integration into
									existing structures.
								</li>
								<li>
									<b>Fire Safety System Design</b>: Development of comprehensive
									fire safety solutions tailored to meet regulatory requirements
									and safety standards for a wide range of facilities.
								</li>
								<li>
									<b>Building Systems Design</b>: Integration of smart building
									solutions, including automation, ventilation, and sustainable
									design, ensuring comfort and energy efficiency in all
									projects.
								</li>
							</ul>
						</div>
					</div>

					<div className='flex flex-col items-center gap-10 lg:flex-row'>
						<ImageWrapper src={ConsultancyImg} />
						<div className='flex flex-col gap-5 text-justify'>
							<h3 className='section-title mx-auto text-center !text-2xl font-semibold !text-black'>
								Consultancy
							</h3>

							<p>
								<b>Sawaed</b> offers consultancy services aimed at guiding
								clients through the complexities of construction, industrial
								installations, and building systems design. Our consultants
								leverage their deep industry knowledge to provide advice on
								project planning, system design, and regulatory compliance.
							</p>

							<ul className='list-disc pl-14'>
								<li>
									<b>Project Planning & Strategy</b>: Helping clients to define
									project scopes, timelines, and budgets, ensuring efficient use
									of resources and adherence to deadlines.
								</li>
								<li>
									<b>Systems Optimization</b>: Providing expert advice on
									optimizing HVAC, electrical, and fire safety systems to reduce
									operational costs and improve performance.
								</li>
								<li>
									<b>Industrial Solutions</b>: Offering consultancy for
									industrial projects, including warehouses, factories, and
									other facilities requiring specialized construction and
									systems installation.
								</li>
							</ul>
						</div>
					</div>

					<div className='flex flex-col items-center gap-10 lg:flex-row-reverse'>
						<ImageWrapper src={ProjectManagementImg} />
						<div className='flex flex-col gap-5 text-justify'>
							<h3 className='section-title mx-auto text-center !text-2xl font-semibold !text-black'>
								Project Management
							</h3>

							<p>
								Our project management team oversees every phase of construction
								and system installation, ensuring seamless execution from
								inception to completion. We coordinate all aspects of the
								project, working closely with clients, contractors, and
								suppliers to meet deadlines and budgets.
							</p>

							<ul className='list-disc pl-14'>
								<li>
									<b>Project Management</b>: Full-service management of
									construction projects, from the initial design phase through
									to construction and system installation, ensuring that all
									aspects are completed to the client’s satisfaction.
								</li>
								<li>
									<b>Quality Assurance</b>: Monitoring project quality at every
									stage, ensuring compliance with international standards and
									client requirements.
								</li>
								<li>
									<b>Maintenance & Support</b>: Offering post-construction
									maintenance and support services to ensure the ongoing
									performance of installed systems, including HVAC, electrical,
									and fire safety solutions.
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
